var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('el-form',{staticStyle:{"margin":"20px","width":"60%","min-width":"600px"},attrs:{"label-position":'left',"label-width":"80px"}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"手机号"}},[_c('el-input',{attrs:{"disabled":_vm.editMode == true},model:{value:(_vm.queryPhone),callback:function ($$v) {_vm.queryPhone=$$v},expression:"queryPhone"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onGetAidaUser}},[_vm._v("同步用户")])],1)],1),_c('el-row',[_c('el-form-item',[(_vm.aidaUser && _vm.aidaUser.userId)?_c('el-card',{staticClass:"box-card"},[_c('div',[_vm._v("userId: "+_vm._s(_vm.aidaUser.userId))]),_c('div',[_vm._v("userId: "+_vm._s(_vm.aidaUser.userPhone))]),_c('div',[_vm._v("userId: "+_vm._s(_vm.aidaUser.userMail))]),_c('div',[_vm._v("TODO, 用户买过的课")])]):_vm._e()],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"开始时间"}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"date","placeholder":"选择日期"},model:{value:(_vm.startDay),callback:function ($$v) {_vm.startDay=$$v},expression:"startDay"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',[_c('el-time-select',{attrs:{"placeholder":"选择时间","picker-options":{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"结束时间"}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.endDay),callback:function ($$v) {_vm.endDay=$$v},expression:"endDay"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',[_c('el-time-select',{attrs:{"placeholder":"选择时间","picker-options":{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)],1)],1),_c('el-row',[_c('el-form-item',{attrs:{"label":"标题"}},[_c('el-input',{attrs:{"placeholder":"标题"},model:{value:(_vm.userLearnPlan.title),callback:function ($$v) {_vm.$set(_vm.userLearnPlan, "title", $$v)},expression:"userLearnPlan.title"}})],1)],1),_c('el-row',[_c('el-input',{attrs:{"type":"textarea","rows":10,"placeholder":"请输入内容"},model:{value:(_vm.userLearnPlan.planText),callback:function ($$v) {_vm.$set(_vm.userLearnPlan, "planText", $$v)},expression:"userLearnPlan.planText"}})],1),_c('el-row',[(_vm.aidaUser && _vm.aidaUser.cosBasicPath)?_c('uploadBox',{ref:"uploadBox",attrs:{"cosPath":_vm.aidaUser.cosBasicPath,"propItems":_vm.propUploadItems}}):_vm._e()],1),_c('el-row',{staticClass:"button-row"},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onAddEdit}},[_vm._v("立即创建")]),_c('el-button',[_vm._v("取消")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }