<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 60%; min-width: 600px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号">
            <el-input v-model="queryPhone" :disabled="editMode == true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="onGetAidaUser">同步用户</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-card class="box-card" v-if="aidaUser && aidaUser.userId">
            <div>userId: {{ aidaUser.userId }}</div>
            <div>userId: {{ aidaUser.userPhone }}</div>
            <div>userId: {{ aidaUser.userMail }}</div>
            <div>TODO, 用户买过的课</div>
          </el-card>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开始时间">
            <el-date-picker v-model="startDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="startTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="结束时间">
            <el-date-picker v-model="endDay" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="endTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="标题">
          <el-input v-model="userLearnPlan.title" placeholder="标题"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="userLearnPlan.planText"> </el-input>
      </el-row>
      <el-row>
        <uploadBox
          ref="uploadBox"
          :cosPath="aidaUser.cosBasicPath"
          :propItems="propUploadItems"
          v-if="aidaUser && aidaUser.cosBasicPath"
        ></uploadBox>
      </el-row>
      <el-row class="button-row">
        <el-form-item>
          <el-button type="primary" @click="onAddEdit">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { userLearnPlanApi } from "@/api/userLearnPlanApi";
import uploadBox from "@/component/uploadBox.vue";
import { strToEpoch } from "@/util/timeTool.js";

export default {
  components: {
    uploadBox,
  },
  data() {
    return {
      aidaUser: null,
      queryPhone: "",
      propUploadItems: [],

      // queryCourseId: "",
      // courseName: "",

      startDay: "",
      startTime: "",
      endDay: "",
      endTime: "",

      userLearnPlan: {
        id: 0,
        userId: 0,
        courseId: 0,
        title: "",
        planText: "",
        startTime: 0,
        endTime: 0,
        hashFinish: 0,
        attach: [],
        createTime: 0,
        updateTime: 0,
      },

      editMode: false,
    };
  },
  methods: {
    onGetAidaUser() {
      userLearnPlanApi.getAidaUser(this.queryPhone).then((ret) => {
        let { code, data } = ret;
        if (code == 0 && data) {
          this.aidaUser = data;
          console.log("aida user..", this.aidaUser);
        } else {
          msgLogger.warn("没找到用户...");
        }
      });
    },
    // onGetCourse() {
    //   let courseTmp = parseInt(this.queryCourseId);
    //   if (this.queryCourseId && !Number.isNaN(courseTmp)) {
    //     courseApi
    //       .getCourseById(this.queryCourseId)
    //       .then((ret) => {
    //         console.log(ret);
    //         let { code, data } = ret;
    //         if (code == 0) {
    //           this.courseName = data.courseName;
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // },
    onAddEdit() {
      let attach = [];
      if (this.$refs.uploadBox && this.$refs.uploadBox.items) {
        console.log("...", this.$refs.uploadBox.items);
        for (let value of this.$refs.uploadBox.items) {
          attach.push({
            key: value.cosKey,
            url: value.url,
          });
        }
      }
      console.log("attach", attach);
      if (this.aidaUser) {
        this.userLearnPlan.userId = this.aidaUser.userId;
      }

      // console.log(this.startDay, this.startTime, this.endDay, this.endTime);
      let startStr = this.startDay + " " + this.startTime;
      let startTime = strToEpoch(startStr);
      this.userLearnPlan.startTime = startTime;

      let endStr = this.endDay + " " + this.endTime;
      let endTime = strToEpoch(endStr);
      this.userLearnPlan.endTime = endTime;
      this.userLearnPlan.attach = attach;

      userLearnPlanApi
        .addEdit(this.userLearnPlan)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.initData(data.id);
            msgLogger.succeed("保存成功");
          }
        })
        .catch((err) => {});
    },

    async initData(id) {
      let [err, ret] = await this.awaitWrap(userLearnPlanApi.getUserLearnPlan(id));
      this.userLearnPlan = ret.data;
      console.log("this.userLearnPlan", this.userLearnPlan);
      this.propUploadItems = this.userLearnPlan.attach;
      [err, ret] = await this.awaitWrap(userLearnPlanApi.getAidaUserById(this.userLearnPlan.userId));
      this.aidaUser = ret.data;
      this.queryPhone = this.aidaUser.userPhone;
      let [day, hour] = this.epochToDateString(this.userLearnPlan.startTime);
      this.startDay = day;
      this.startTime = hour;
      [day, hour] = this.epochToDateString(this.userLearnPlan.endTime);
      this.endDay = day;
      this.endTime = hour;
    },
    epochToDateString(epoch) {
      if (typeof epoch == "string") {
        epoch = parseInt(epoch);
      }
      let date = new Date(epoch);
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      let h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
      let m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

      return [Y + M + D, h + m];
    },
    awaitWrap(promise) {
      return promise.then((data) => [null, data]).catch((err) => [err, null]);
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.id) {
      this.editMode = true;
      const id = this.$route.query.id;
      this.initData(id);
    }
  },
};
</script>

<style lang="less" scoped>
.main {
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
}
</style>
