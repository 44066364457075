<template>
  <div class="upload-box">
    <div class="box-item" v-for="(item, index) in items" :key="index">
      <uploadItem :percentage="item.percentage" :cosKey="item.cosKey" :url="item.url"></uploadItem>
    </div>
    <div class="box-item">
      <div class="form-group">
        <label class="label">
          <i class="material-icons"></i>
          <span class="title">Add File</span>
          <input multiple type="file" @change="getFile" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import uploadItem from "@/component/uploadItem";
import msgLogger from "@/util/msgLogger";
import { cosApi } from "@/api/cosApi.js";
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";
import COS from "cos-js-sdk-v5";
export default {
  components: {
    uploadItem,
  },
  props: {
    cosPath: String,
    propItems: Array,
  },
  data() {
    return {
      items: [],
      bucket: "",
      region: "",
      test: 0,
    };
  },
  mounted() {
    console.log("upload box mounted");
    this.propItemsSetter(this.propItems);

    cosApi
      .getBucketRegion("private")
      .then((ret) => {
        console.log("getBucket", ret);
        let data = ret.data;
        this.bucket = data.bucket;
        this.region = data.region;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$eventBus.$on("upload-item-delete", (msg) => {
      // A发送来的消息
      console.log("on event", msg);
      const cosKey = msg.cosKey;
      for (let i = this.items.length - 1; i >= 0; i--) {
        if (this.items[i].cosKey == cosKey) {
          this.items.splice(i, 1);
        }
      }
    });
  },
  methods: {
    awaitWrap(promise) {
      return promise.then((data) => [null, data]).catch((err) => [err, null]);
    },
    getFile(e) {
      // 支持多选，可以上传多个文件
      if (!this.cosPath) {
        msgLogger.warn("先同步用户，取得cosPath 才能上传");
        return;
      }
      let uploadFiles = e.target.files;
      console.log("this.uploadFileName", uploadFiles);
      // 初始化 cosClient
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          var url = cosApi.COS_STS_URL;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });
      //逐个上传
      for (let i = 0; i < uploadFiles.length; i++) {
        this.items.push({
          percentage: 0,
          cosKey: "",
          url: "",
        });
        let itemIndex = this.items.length - 1;

        let file = uploadFiles[i];
        let fileName = uploadFiles[i].name;
        console.log("...upload one file", file);
        // 分片上传文件
        let _this = this;
        const cosKey = this.cosPath + "/" + nanoid(8) + "_" + fileName;
        cosClient.sliceUploadFile(
          {
            Bucket: this.bucket,
            Region: this.region,
            Key: cosKey,
            Body: file,
            onTaskReady: function(taskId) {
              /* 非必须 */
              console.log(taskId);
            },
            onHashProgress: function(progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function(progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              _this.items[itemIndex].percentage = progressData.percent * 100;
              // console.log(
              //   "_this",
              //   _this.test,
              //   "i",
              //   itemIndex,
              //   "items.percentage",
              //   _this.items[itemIndex].percentage
              // );
            },
          },
          function(err, data) {
            console.log("...", err, data);
            if (data.statusCode == 200) {
              // 上传成功 data.Bucket, data.Key
              console.log("after upload...", data);
              let cosKey = data.Key;
              userLearnPlanApi.getCdnUrl(cosKey).then((ret) => {
                let { code, data } = ret;
                let url = data;
                console.log("get cdn url", url);
                _this.items[itemIndex].url = url;
                _this.items[itemIndex].cosKey = cosKey;
                console.log("get cdn url", _this.items);
              });
            }
          }
        );
      }
    },
    async propItemsSetter(newValue) {
      for (let item of newValue) {
        let [err, ret] = await this.awaitWrap(userLearnPlanApi.getCdnUrl(item.key));
        let newItem = {
          percentage: 100,
          cosKey: item.key,
          url: ret.data,
        };
        this.items.push(newItem);
      }
      console.log("..prop items", this.items);
    },
  },
  watch: {
    propItems: function(newValue) {},
  },
};
</script>

<style lang="less" scoped>
@input-file-color: grey;
@input-file-hover-color: black;

.upload-box {
  display: flex;
  flex-direction: row;
  .box-item {
    margin: 20px;
    .upload-btn {
      width: 80px;
      height: 80px;
      border: none;
      font-size: 40px;
      font-weight: 300;
    }
  }
}

.form-group {
  .label {
    width: 160px;
    height: 160px;
    border: 2px dashed @input-file-color;
    border-radius: 5px;
    display: block;
    padding: 1.2em;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;

    i {
      display: block;
      font-size: 42px;
      padding-bottom: 16px;
    }

    i,
    .title {
      color: @input-file-color;
      transition: 200ms color;
    }

    &:hover {
      border: 2px solid @input-file-hover-color;

      i,
      .title {
        color: @input-file-hover-color;
      }
    }
    input[type="file"] {
      outline: 0;
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }
  }
}
</style>
