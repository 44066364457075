<template>
  <div class="upload-item">
    <div class="top">
      <i
        class="el-icon-delete"
        v-if="uploadingPercentage == 100"
        @click="onDeleteCos"
      ></i>
      <i
        class="el-icon-view"
        v-if="uploadingPercentage == 100"
        style="margin: 0px 10px"
        @click="dialogVisible = true"
      ></i>
    </div>
    <div class="main-body">
      <img v-if="uploadingPercentage == 100 && isImage" :src="uploadingUrl" />
      <video
        v-else-if="uploadingPercentage == 100 && isVideo"
        :src="uploadingUrl"
      ></video>
      <el-progress
        v-if="uploadingPercentage < 100"
        :text-inside="true"
        :stroke-width="26"
        :percentage="uploadingPercentage"
      ></el-progress>
    </div>
    <el-dialog title="预览" :visible.sync="dialogVisible" width="60%">
      <div class="preview">
        <img v-if="uploadingPercentage == 100 && isImage" :src="uploadingUrl" />
        <video
          controls
          v-else-if="uploadingPercentage == 100 && isVideo"
          :src="uploadingUrl"
        ></video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userLearnPlanApi } from "@/api/userLearnPlanApi.js";

export default {
  props: {
    // icon: String,
    // url: String,
    percentage: Number,
    cosKey: String,
    url: String,
  },
  data() {
    return {
      uploadingPercentage: this.percentage,
      uploadingCosKey: this.cosKey,
      uploadingUrl: this.url,
      isImage: false,
      isVideo: false,
      dialogVisible: false,
    };
  },
  mounted() {
    console.log("upload item mounted");
    this.cosKeySetter(this.cosKey);
    this.urlSetter(this.url);
  },
  methods: {
    getPostFix(url) {
      let fields = url.split(".");
      if (!fields) {
        return "";
      }
      return fields[fields.length - 1].toLowerCase();
    },
    onDeleteCos() {
      userLearnPlanApi
        .deleteCos(this.uploadingCosKey)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            // 发送事件
            this.$eventBus.$emit("upload-item-delete", {
              percentage: this.uploadingPercentage,
              cosKey: this.uploadingCosKey,
              url: this.uploadingUrl,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    percentageSetter(newValue) {
      if (newValue > 100) {
        this.uploadingPercentage = 100;
      } else {
        this.uploadingPercentage = newValue;
      }
    },
    cosKeySetter(newValue) {
      this.uploadingCosKey = newValue;
      let postFix = this.getPostFix(newValue);
      if (
        postFix == "png" ||
        postFix == "jpg" ||
        postFix == "jpeg" ||
        postFix == "bmp"
      ) {
        this.isImage = true;
      }
      if (postFix == "mp4") {
        this.isVideo = true;
      }
      //console.log(this.uploadingCosKey, "upload item, coskey");
    },
    urlSetter(newValue) {
      this.uploadingUrl = newValue;
      //console.log(this.uploadingUrl, "upload item, url..");
    },
  },
  watch: {
    percentage: function(newValue) {
      this.percentageSetter(newValue);
    },
    cosKey: function(newValue) {
      this.cosKeySetter(newValue);
    },
    url: function(newValue) {
      this.urlSetter(newValue);
    },
  },
};
</script>

<style lang="less" scoped>
.upload-item {
  height: 160px;
  width: 160px;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    flex-direction: row-reverse;
    height: 20px;
  }
  img {
    max-height: 140px;
    max-width: 140px;
  }
  video {
    max-height: 140px;
    max-width: 140px;
  }
}
.preview {
  width: 100%;
  img {
    max-height: 80%;
    max-width: 80%;
  }
  video {
    max-height: 80%;
    max-width: 80%;
  }
}
</style>
